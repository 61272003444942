import classNames from 'classnames';
import React from 'react';

const EdnaInputCheckbox = (props) => {
  const {
    consentFieldId,
    consentFieldName,
    defaultChecked,
    disabled,
    children,
    required = true,
  } = props;

  const checkboxClasses = classNames({
    'edna-input-checkbox': true,
    'edna-input-checkbox--disabled': disabled,
  });

  return (
    <div className={checkboxClasses}>
      <input
        id={consentFieldId || consentFieldName}
        name={props.consentFieldName}
        className="edna-input-checkbox__input"
        type="checkbox"
        required={required}
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
      <label className="edna-input-checkbox__label" htmlFor={consentFieldId || consentFieldName}>
        {children}
      </label>
    </div>
  );
};

export default EdnaInputCheckbox;
