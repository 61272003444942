import { canUseDOM } from 'exenv';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useCallback, useRef } from 'react';

import { usePrivacyBanner } from '../../hooks/usePrivacy';

import Details from './sections/details';
import Groups from './sections/groups';

const Cookieconsent = ({ groups, updateCallback }) => {
  const ref = useRef();
  const { shouldShowPrivacyBanner, setShouldShowPrivacyBanner } = usePrivacyBanner();

  const onAllowAll = useCallback(() => {
    const all = groups.map(() => true);
    updateCallback(all);
    setShouldShowPrivacyBanner(false);
  }, [groups, updateCallback]);

  const onAllowSelection = useCallback(() => {
    const { current } = ref;
    const selection = groups.map(({ key }) => current.querySelector(`[name=${key}]`).checked);
    updateCallback(selection);
    setShouldShowPrivacyBanner(false);
  }, [ref, groups, updateCallback]);

  if (!canUseDOM) {
    return <div className="cookieconsent" />;
  }

  return (
    <div
      className="cookieconsent"
      role="dialog"
      aria-modal="true"
      aria-hidden={!shouldShowPrivacyBanner}
      aria-labelledby="cookieconsent-title"
      aria-describedby="cookieconsent-details"
    >
      <div ref={ref}>
        <div className="cookieconsent__wrapper">
          <h2 className="cookieconsent__title" id="cookieconsent-title">
            Privacy settings
          </h2>
          <Details id="cookieconsent-details" />
          <Groups groups={groups} />

          <div className="cookieconsent__controls">
            <button
              className="cookieconsent__controls__button"
              type="button"
              onClick={onAllowSelection}
            >
              Allow selection
            </button>

            <button className="button" type="button" onClick={onAllowAll}>
              Allow all
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookieconsent;

Cookieconsent.propTypes = {
  groups: arrayOf(
    shape({
      key: string.isRequired,
      title: string.isRequired,
      description: string.isRequired,
      allowed: bool,
    })
  ).isRequired,
  updateCallback: func.isRequired,
};
