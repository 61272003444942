import classNames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// checks user inputs to customize focus style on different devices
import 'what-input';

import Privacycontrol from './cookieconsent/privacycontrol';
import Footer from './footer';
import Navbar from './navbar';

// Sass file entry point
import '../styles/main.scss';

const Layout = ({ className, children, navbarIsInverted, pageColorInverted, pathname }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPartNavigation(filter: { title: { ne: "dummy-info" } }) {
          edges {
            node {
              title
              routes {
                ... on Node {
                  ... on ContentfulLayout {
                    __typename
                    navLinkText
                    url
                    whatRegion
                  }
                }
                ... on Node {
                  ... on ContentfulPartLink {
                    __typename
                    title
                    url
                  }
                }
              }
              secundaryRoutes {
                ... on Node {
                  ... on ContentfulLayout {
                    __typename
                    navLinkText
                    url
                    whatRegion
                  }
                }
                ... on Node {
                  ... on ContentfulPartLink {
                    __typename
                    title
                    url
                  }
                }
              }
              image {
                title
                file {
                  contentType
                  url
                }
              }
            }
          }
        }
        allContentfulPartFooter(filter: { title: { ne: "dummy-info" } }) {
          edges {
            node {
              title
              image {
                title
                file {
                  contentType
                }
                gatsbyImageData(layout: CONSTRAINED, width: 900)
              }
              siteLinks {
                title
                url
              }
              officeLinks {
                city
                slug
              }
              copyrightNotice
              signupForm {
                title
                description {
                  childMarkdownRemark {
                    html
                  }
                }
                formActionUrl
                emailFieldName
                honeypotFieldName
                fnameFieldName
                lnameFieldName
                companyFieldName
                theme
                image {
                  title
                  file {
                    url
                  }
                }
                button_text
                consentCheckBoxes {
                  consentFieldName
                  label {
                    childMarkdownRemark {
                      html
                    }
                  }
                  required
                }
                privacyNotice {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
        allContentfulPartGlobals(filter: { siteName: { ne: "dummy-info" } }) {
          edges {
            node {
              siteName
              edenspiekermannLogoColor {
                title
                file {
                  url
                }
              }
              twitterUrl
              facebookUrl
              linkedinUrl
              instagramUrl
              devBlogUrl
            }
          }
        }
      }
    `}
    render={(props) => {
      const footerProps = props.allContentfulPartFooter.edges[0].node;

      const [isIframe, setIsIframe] = useState(false);

      // Disable the navigation if we specify a special query param. Used for embedding in the new ESPI website
      useEffect(() => {
        if (new URLSearchParams(window.location.search).get('iframe-embed')) {
          setIsIframe(true);
          calculateAndSendHeight();
        }
      });

      const {
        edenspiekermannLogoColor,
        twitterUrl,
        facebookUrl,
        linkedinUrl,
        instagramUrl,
        devBlogUrl,
      } = props.allContentfulPartGlobals.edges[0].node;

      const { routes, image, secundaryRoutes } = props.allContentfulPartNavigation.edges[0].node;

      const wrapClasses = classNames(
        {
          'page-wrap': true,
          'page-wrap--inverted': pageColorInverted,
          'iframe': isIframe,
        },
        className
      );

      // Register iframe post message
      useEffect(() => {
        window.onload = calculateAndSendHeight;
        // calculateAndSendHeight();
        window.addEventListener('resize', calculateAndSendHeight);

        return () => {
          window.removeEventListener('resize', calculateAndSendHeight);
        };
      });

      return (
        <div className={wrapClasses}>
          <div className="page-content-wrap">
            <Privacycontrol />
            {!isIframe && (
              <Navbar
                navbarIsInverted={navbarIsInverted}
                routes={routes}
                secundaryRoutes={secundaryRoutes}
                image={image}
                pathname={pathname}
                twitterUrl={twitterUrl}
                facebookUrl={facebookUrl}
                linkedinUrl={linkedinUrl}
                instagramUrl={instagramUrl}
                devBlogUrl={devBlogUrl}
              />
            )}
            <>{children}</>
            {!isIframe && (
              <Footer
                {...footerProps}
                twitterUrl={twitterUrl}
                facebookUrl={facebookUrl}
                linkedinUrl={linkedinUrl}
                instagramUrl={instagramUrl}
                devBlogUrl={devBlogUrl}
              />
            )}
          </div>
        </div>
      );
    }}
  />
);

// Iframe communication to report height to parent window
function calculateAndSendHeight() {
  const iframeHeight = document.body.scrollHeight;

  window.parent.postMessage(
    {
      type: 'iframeHeight',
      height: iframeHeight,
    },
    '*'
  );
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  navbarIsInverted: PropTypes.bool,
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
