import React from 'react';

import { usePrivacy, usePrivacyBanner } from '../../hooks/usePrivacy';

import Cookieconsent from './cookieconsent';

const Privacycontrol = () => {
  const { groups, update: updateCallback } = usePrivacy();
  const { shouldShowPrivacyBanner } = usePrivacyBanner();

  if (shouldShowPrivacyBanner) {
    return <Cookieconsent groups={groups} updateCallback={updateCallback} />;
  }
  return null;
};

export default Privacycontrol;
