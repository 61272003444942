import jsonp from 'jsonp';
import PropTypes from 'prop-types';
import React from 'react';
import toQueryString from 'to-querystring';

import NewsletterForm from './newsletter-form';

const getAjaxUrl = (url) => url.replace('/post?', '/post-json?');

class MailchimpSubscribeWrapper extends React.Component {
  state = {
    status: null,
    message: null,
  };

  subscribe = (data) => {
    const params = toQueryString(data);
    const url = `${getAjaxUrl(this.props.url)}&${params}`;
    this.setState(
      {
        status: 'sending',
        message: null,
      },
      () =>
        jsonp(
          url,
          {
            param: 'c',
          },
          (err, data) => {
            if (err) {
              this.setState({
                status: 'error',
                message: err,
              });
            } else if (data.result !== 'success') {
              this.setState({
                status: 'error',
                message: data.msg,
              });
            } else {
              this.setState({
                status: 'success',
                message: data.msg,
              });
            }
          }
        )
    );
  };

  render() {
    return this.props.render({
      subscribe: this.subscribe.bind(this),
      status: this.state.status,
      message: this.state.message,
    });
  }
}

MailchimpSubscribeWrapper.propTypes = {
  render: PropTypes.func,
  url: PropTypes.string.isRequired,
};

MailchimpSubscribeWrapper.defaultProps = {
  render: ({ subscribe, status, message }) => (
    <NewsletterForm status={status} message={message} onSubmitted={subscribe} />
  ),
};

export default MailchimpSubscribeWrapper;
