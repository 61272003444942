/* Constants */

// Breakpoint numbers from _layout.scss to help determine img srcsets in JS
export const BP = {
  small: '370px',
  smedium: '621px',
  medium: '800px',
  large: '1024px',
  xlarge: '1200px',
  max: '1440px',
  container: '1480px',
};

export const SOCIALS = {
  twitter: 'https://twitter.com/',
  facebook: 'https://facebook.com/',
  linkedin: 'https://www.linkedin.com/in/',
  linkedinCompany: 'https://www.linkedin.com/company/',
  instagram: 'https://www.instagram.com/',
};

export const ESPI_TWITTER = '@edenspiekermann';

export const ESPI_URL = 'https://www.edenspiekermann.com';

export const REGIONS = {
  us: 'us',
  eu: 'eu',
  asia: 'asia',
  intl: 'intl',
};

// Asia Country ISO Codes
// prettier-ignore
export const ASIA_CODES = ['as', 'aq', 'au', 'bd', 'bt', 'io', 'bn', 'kh', 'cn', 'cx', 'cc', 'ck', 'fj', 'pf', 'tf', 'gu', 'hm', 'hk', 'in', 'id', 'jp', 'ki', 'kg', 'kr', 'la', 'mo', 'my', 'mv', 'mh', 'fm', 'mn', 'mm', 'nr', 'np', 'nc', 'nz', 'nu', 'nf', 'mp', 'pk', 'pw', 'pg', 'ph', 'pn', 'ws', 'sg', 'sb', 'ss', 'lk', 'tw', 'tj', 'th', 'tl', 'tk', 'to', 'tm', 'tv', 'um', 'uz', 'vu', 'vn', 'wf'];

// Europe ISO Code
// prettier-ignore
export const EU_CODES = ['ad', 'al', 'at', 'ba', 'be', 'bg', 'by', 'ch', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fo', 'fr', 'gb', 'gi', 'gr', 'hr', 'hu', 'ie', 'is', 'it', 'li', 'lt', 'lu', 'lv', 'mc', 'md', 'mk', 'mt', 'nl', 'no', 'pl', 'pt', 'ro', 'se', 'si', 'sj', 'sk', 'sm', 'ua', 'va'];

/* Helper Functions */

// Remove duplicate values of key in an array of objects
export const removeDuplicates = (originalArray, key) => {
  const newArray = [];
  const lookupObject = {};

  for (const i in originalArray) {
    lookupObject[originalArray[i][key]] = originalArray[i];
  }

  for (const i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

// Check slug from Contentful
// if it's `offices` instead of `/offices`, then add the forward slash
export const checkSlugForPrecedingSlash = (slug) => {
  if (typeof slug !== 'string') {
    throw new Error('Slug is not defined');
  }
  if (slug.charAt(0) !== '/') {
    return `/${slug}`;
  }
  return slug;
};

// Return previous index (accounts for index 0)
export const priorIndex = (currentIndex, total) => {
  if (currentIndex === 0) {
    return total - 1;
  }
  return currentIndex - 1;
};

export const nextIndex = (currentIndex, total) => {
  if (currentIndex === total - 1) {
    return 0;
  }
  return currentIndex + 1;
};

// Validate a JSON string
export const isValidJson = (json) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

export const capitalize = (str) => str.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

/**
 * groupJobsByOffice
 *
 * groups the jobs from the Breezy API response by office (city)
 * to display them on the jobs overview page
 * @param  {Array} jobs the jobs array from the API response
 * @return {Array}      the jobs grouped by Office
 */
export const groupJobsByOffice = (jobs) => {
  if (!Array.isArray(jobs)) {
    return [];
  }

  const offices = {};
  jobs.forEach((job) => {
    if (!job.node.office) {
      return;
    }

    const office = capitalize(job.node.office.trim());
    offices[office] = offices[office] || [];
    offices[office].push(job.node);
  });

  return Object.entries(offices)
    .map(([office, jobs]) => ({
      office,
      // Sort jobs by name:
      jobs: jobs.sort((a, b) => (a.name < b.name ? -1 : 1)),
    }))
    .sort((a, b) => {
      // Always sort "Berlin" to be first entry:
      if (a.office === 'Berlin') {
        return Math.min;
      }
      if (b.office === 'Berlin') {
        return Math.max;
      }

      return a.office < b.office ? -1 : 1;
    });
};

/**
 * localeLink
 *
 * check if region exists and if so append to the internal link
 * @param  {String} location a region variable from contentful
 * @param  {String} to the url without the region, if undefined then it will go to homepage
 * @return {String} the correct url to use for the gatsby link component
 */
export const localeLink = (location, to) => {
  const keys = Object.keys(REGIONS);
  const matchLocation = keys.includes(location);
  const path = to.split('/').filter((segment) => !!segment.trim());

  if (matchLocation) {
    path.push(location);
  }
  return `/${path.join('/')}`;
};
