import Background from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { bool } from 'prop-types';
import React from 'react';
import { imageType } from '../types/image';

const Image = ({ image, asBackground, ...props }) => {
  const { contentType, url } = image?.file || {};
  const currImage = getImage(image);

  switch (contentType) {
    case 'image/gif':
      if (asBackground) {
        return <div style={{ backgroundImage: `url(${url})` }} {...props} />;
      }
      return (
        <picture>
          {image.gatsbyImageData.images.sources.map(({ type, srcSet, sizes }) => (
            <source type={type} sizes={sizes} srcSet={srcSet}></source>
          ))}
          <img
            width={image.gatsbyImageData.width}
            height={image.gatsbyImageData.height}
            sizes={image.gatsbyImageData.images.sources[0].sizes}
            srcSet={image.gatsbyImageData.images.sources[0].srcSet}
            alt={image.title || ''}
          />
        </picture>
      );
    case 'image/svg':
    case 'image/svg+xml':
      if (asBackground) {
        return <div style={{ backgroundImage: `url(${url})` }} {...props} />;
      }
      return <img src={url} {...props} />;
    default:
      if (asBackground) {
        return <Background {...convertToBgImage(currImage)} {...props} alt={image.title} />;
      }
      return <GatsbyImage image={currImage} {...props} alt={image.title} />;
  }
};

export default Image;

Image.propTypes = {
  image: imageType.isRequired,
  asBackground: bool,
};

Image.defaultProps = {
  asBackground: false,
};
