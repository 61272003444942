import TransitionLink from 'gatsby-plugin-transition-link';
import React from 'react';

import { checkSlugForPrecedingSlash } from '../helpers';

const NOOP = () => {};

const NOOP_EXIT_TRANSITION = {
  length: 0,
  trigger: ({ node }) => NOOP_EXIT_TRANSITION.exitTrigger(node),
  exitTrigger: NOOP,
};

const NOOP_ENTRY_TRANSITION = {
  length: 0,
  trigger: ({ node }) => NOOP_ENTRY_TRANSITION.exitTrigger(node),
  entryTrigger: NOOP,
};

const EXIT_TRANSITION = {
  length: 0.5,
  zIndex: 2,
  trigger: ({ node }) => {
    EXIT_TRANSITION.exitTrigger(node);
    if (node) node.style.top = `${-window.pageYOffset}px`;
    window.scrollTo({ top: -window.pageYOffset });
  },
  exitTrigger: NOOP,
};

const ENTRY_TRANSITION = {
  length: 1.0,
  zIndex: 1,
  trigger: ({ node }) => {
    const pageWrap = node.querySelector('.page-content-wrap');
    pageWrap.classList.add('page-content-wrap--entry');
    ENTRY_TRANSITION.entryTrigger(node);
  },
  entryTrigger: NOOP,
};

export const Link = ({ link, to, children, noExitTransition, noEntryTransition, ...props }) => (
  <TransitionLink
    exit={noExitTransition ? NOOP_EXIT_TRANSITION : EXIT_TRANSITION}
    entry={noEntryTransition ? NOOP_ENTRY_TRANSITION : ENTRY_TRANSITION}
    to={checkSlugForPrecedingSlash(link?.href ?? to)}
    {...props}
  >
    {children || link?.label}
  </TransitionLink>
);

export default Link;
