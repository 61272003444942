import React from 'react';

import MailchimpSubscribeWrapper from '../components/mailchimp-subscribe-wrapper';
import Markdown from '../components/markdown';
import NewsletterForm from '../components/newsletter-form';
import { usePrivacyConsent } from '../hooks/usePrivacy';

const BlockNewsletterSignup = (props) => {
  const { title, description, formActionUrl } = props;
  const canUseMarketing = usePrivacyConsent('marketing');

  return (
    <div className="newsletter-signup-block block-content">
      <div className="newsletter-signup-block__inner">
        {!canUseMarketing ? (
          <a target="_blank" rel="noopener noreferrer" className="button" href={formActionUrl}>
            Sign up
          </a>
        ) : (
          <>
            <div className="newsletter-signup-block__item">
              <div className="newsletter-signup-block__title">
                {title && <h2>{title}</h2>}

                {description && <Markdown markdown={description.childMarkdownRemark.html} />}
              </div>
            </div>
            <div className="newsletter-signup-block__item">
              <div className="newsletter-signup-block__form-wrapper">
                <MailchimpSubscribeWrapper
                  url={formActionUrl}
                  render={({ subscribe, status, message }) => (
                    <NewsletterForm
                      status={status}
                      message={message}
                      onSubmitted={(formData) => subscribe(formData)}
                      {...props}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlockNewsletterSignup;
