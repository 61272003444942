import React from 'react';

const Details = ({ id }) => (
  <div className="cookieconsent__details" id={id}>
    <p>
      We use cookies to ensure that we give you the best experience possible. To custom-tailor our
      website to your needs we use cookies. Click allow all to get the most convenient website
      experience possible. Change your preference by clicking the link in the footer. For more
      information, please read our{' '}
      <a href="/content/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Notice
      </a>
      .
    </p>
  </div>
);

export default Details;
