import React from 'react';

import { ReactComponent as IconDevBlog } from '../assets/images/icons/socials/icon-devblog.svg';
import { ReactComponent as IconFacebook } from '../assets/images/icons/socials/icon-facebook.svg';
import { ReactComponent as IconInstagram } from '../assets/images/icons/socials/icon-insta.svg';
import { ReactComponent as IconLinkedin } from '../assets/images/icons/socials/icon-linkedin.svg';
import { ReactComponent as IconTwitter } from '../assets/images/icons/socials/icon-twitter.svg';
import { SOCIALS } from '../helpers.js';

// import the social icons as components via the 'gatsby-plugin-svgr' package

const SocialLinks = (props) => {
  const { devBlogUrl, facebookUrl, instagramUrl, linkedinUrl, twitterUrl } = props;

  // Linkedin has two different url structures, one for people and one for companies.
  // For linking espi company account we need another linkedin url
  const isCompanyLinkedin = linkedinUrl === 'edenspiekermann';
  const linkedinBaseUrl = isCompanyLinkedin ? SOCIALS.linkedinCompany : SOCIALS.linkedin;

  return (
    <div className="social-links">
      {facebookUrl && (
        <a
          className="social-link"
          href={`${SOCIALS.facebook}${facebookUrl}`}
          aria-label="Edenspiekermann on Facebook"
        >
          <IconFacebook className="icon icon--social" />
        </a>
      )}
      {instagramUrl && (
        <a
          className="social-link"
          href={`${SOCIALS.instagram}${instagramUrl}`}
          aria-label="Edenspiekermann on Instagram"
        >
          <IconInstagram className="icon icon--social" />
        </a>
      )}
      {linkedinUrl && (
        <a
          className="social-link"
          href={`${linkedinBaseUrl}${linkedinUrl}`}
          aria-label="Edenspiekermann on LinkedIn"
        >
          <IconLinkedin className="icon icon--social" />
        </a>
      )}
      {twitterUrl && (
        <a
          className="social-link"
          href={`${SOCIALS.twitter}${twitterUrl}`}
          aria-label="Edenspiekermann on Twitter"
        >
          <IconTwitter className="icon icon--social" />
        </a>
      )}
      {devBlogUrl && (
        <a className="social-link" href={devBlogUrl} aria-label="Edenspiekermann Developer Blog">
          <IconDevBlog className="icon icon--social" />
        </a>
      )}
    </div>
  );
};

export default SocialLinks;
