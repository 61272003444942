import { arrayOf, bool, shape, string } from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { usePolyfill } from '../../../hooks/usePolyfill';
import usePrefersReducedMotion from '../../../hooks/usePrefersReducedMotion';
import EdnaInputCheckbox from '../../edna-input-checkbox';

const ANIMATION_DURATION = 400;
const ANIMATION_EASING = 'ease-in-out';

const Group = ({ id, disabled, allowed, title, description }) => {
  const el = useRef(null);
  const summary = useRef(null);
  const content = useRef(null);

  const prefersReducedMotion = usePrefersReducedMotion();

  // Polyfill for web animation API
  usePolyfill(
    () => 'animate' in el.current,
    () =>
      import(/* webpackChunkName: "web-animations-js" */ 'web-animations-js').then((module) => {
        module.default.polyfill();
        return module;
      })
  );

  const animate = useCallback(async (el, from, to, options = null) => {
    if (prefersReducedMotion) {
      return Promise.resolve();
    }

    const keyframes = {};
    Object.entries(from).forEach(([key, value]) => {
      if (!to[key]) {
        // skip if there is no end style defined...
        return;
      }

      keyframes[key] = [value, to[key]];
    });

    return new Promise((resolve, reject) => {
      const animation = el.animate(keyframes, {
        duration: ANIMATION_DURATION,
        easing: ANIMATION_EASING,
        ...options,
      });

      animation.onfinish = () => resolve();
      animation.oncancel = () => reject(new Error());
    });
  }, []);

  const toggle = useCallback((e) => {
    if (e.currentTarget !== e.target) {
      return;
    }
    e.preventDefault();
    const nextOpen = !el.current.open;
    const from = `${el.current.offsetHeight}px`;

    let to;
    if (!el.current.open) {
      el.current.open = true;
      to = `${summary.current.offsetHeight + content.current.offsetHeight}px`;
    } else {
      to = `${summary.current.offsetHeight}px`;
    }

    el.current.style.overflow = 'hidden';
    el.current.style.height = from;
    animate(el.current, { height: from }, { height: to })
      .then(() => {
        el.current.open = nextOpen;
      })
      .finally(() => {
        el.current.style.overflow = '';
        el.current.style.height = '';
      });
  }, []);

  return (
    <li className="cookieconsent__groups__group">
      <details ref={el}>
        <summary ref={summary} onClick={toggle} className="cookieconsent__groups__group__title">
          <EdnaInputCheckbox
            consentFieldName={id}
            consentFieldId={`consent-key-${id}`}
            disabled={disabled}
            defaultChecked={allowed || false}
          >
            {title}
          </EdnaInputCheckbox>
        </summary>
        <div ref={content}>
          <p id={`consent-description-${id}`} className="cookieconsent__groups__group__description">
            {description}
          </p>
        </div>
      </details>
    </li>
  );
};

const Groups = ({ groups }) => {
  // Add a default consent group for "necessary" functions:
  const consents = useMemo(
    () =>
      [
        {
          key: 'necessary',
          title: 'Necessary',
          description:
            'These cookies are essential for you to browse the website and use its features.',
          allowed: true,
          disabled: true,
        },
      ].concat(groups),
    [groups]
  );

  return (
    <ul className="cookieconsent__groups">
      {consents.map(({ key, title, description, allowed, disabled }) => (
        <Group
          key={key}
          id={key}
          title={title}
          description={description}
          allowed={allowed}
          disabled={disabled}
        />
      ))}
    </ul>
  );
};

export default Groups;

Groups.propTypes = {
  groups: arrayOf(
    shape({
      key: string.isRequired,
      title: string.isRequired,
      description: string.isRequired,
      allowed: bool,
    })
  ).isRequired,
};
