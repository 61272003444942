/* eslint-disable no-case-declarations */
const { define } = require('routz');

const ROUTES = {
  'index': '/[locale?]/',
  'cases:list': '/case-studies/[locale?]/',
  'cases:detail': '/case-studies/[caseSlug]/',
  'services:list': '/services/[locale?]/',
  'services:detail': '/services/[serviceSlug]/',
  'industry:detail': '/industry/[industrySlug]/',
  'manifesto': '/manifesto/',
  'about': '/about/',
  'offices:list': '/offices/',
  'offices:detail': '/offices/[officeSlug]',
  'jobs:list': '/jobs/',
  'jobs:detail': '/jobs/[jobSlug]',
  'jobs:apply': '/jobs/[jobSlug]/apply',
  'insights:home': '/insights/',
  'insights:list': '/insights/page/[insightPage]',
  'insights:detail': '/insights/[insightSlug]',
  'news:list': '/news-events/page/[newsPage]',
  'interviews:list': '/interviews/page/[interviewPage]',
  'articles:list': '/article/page/[articlePage]',
  'people:list': '/people/',
  'people:detail': '/people/[peopleSlug]',
  'content:detail': '/content/[contentSlug]',
  'pages:detail': '/[pageSlug]/[locale?]/',
};
module.exports.ROUTES = ROUTES;

const { receive, resolve } = define(ROUTES);
module.exports.receive = receive;
module.exports.resolve = resolve;

/**
 * Builds a link as internal link to a generic page (part of this website).
 */
const buildLink = (label, href) => ({ label, href });
module.exports.buildLink = buildLink;

/**
 * Builds a link and its properties, based on a given CMS link entry.
 */
const getLink = (item, loc = '') => {
  const { __typename: typename } = item;

  switch (typename) {
    case 'ContentfulLayout':
      const { url: pageSlug, navLinkText, whatRegion } = item;
      if (pageSlug === '/') {
        return buildLink(navLinkText, resolve('index', { locale: loc ?? whatRegion }));
      }

      // Someone had the inspiration, that the value for all regions in the "whatRegion"
      // field of the CMS is "global - this page is same content for all regions".
      // So we need to test of the value contains "global"... YOLO!
      if (/global/.test(whatRegion)) {
        // If the page is accessible for all regions, we can omit the locale...
        return buildLink(navLinkText, resolve('pages:detail', { pageSlug }));
      }

      return buildLink(
        navLinkText,
        resolve('pages:detail', { locale: loc || whatRegion, pageSlug })
      );

    case 'ContentfulMagazine':
      const { slug: insightSlug, title: insightLabel } = item;
      return buildLink(insightLabel, resolve('insights:detail', { insightSlug }));

    case 'ContentfulProject':
      const { slug: caseSlug, title: caseLabel } = item;
      return buildLink(caseLabel, resolve('cases:detail', { caseSlug }));

    case 'ContentfulSectionArrowText':
      const {
        ctaLabel: arrowTextLabel,
        ctaInternalLink: { url: arrowTextSlug },
      } = item;
      return buildLink(arrowTextLabel, resolve('pages:detail', { pageSlug: arrowTextSlug }));

    case 'ContentfulSectionCta':
      const { buttonSlug: sectionCtaSlug, buttonText: sectionCtaLabel } = item;
      return buildLink(sectionCtaLabel, resolve('pages:detail', { pageSlug: sectionCtaSlug }));

    case 'ContentfulSectionHomepageMessage':
      const {
        ctaLabel: homepageMessageLabel,
        ctaInternalLink: { url: homepageMessageSlug },
      } = item;
      return buildLink(
        homepageMessageLabel,
        resolve('pages:detail', { pageSlug: homepageMessageSlug })
      );

    case 'ContentfulSectionGenericCta':
      const {
        ctaLabel: genericCtaLabel,
        ctaInternalLink: { url: genericCtaSlug },
      } = item;
      return buildLink(genericCtaLabel, resolve('pages:detail', { pageSlug: genericCtaSlug }));

    case 'ContentfulBlockTopicBanner':
      const {
        topic_1: { slug: topicBannerSlug, title: topicBannerLabel },
      } = item;
      return buildLink(topicBannerLabel, resolve('pages:detail', { pageSlug: topicBannerSlug }));

    case 'ContentfulSectionArticleTeaser':
      const { buttonSlug: articleTeaserSlug, buttonText: articleTeaserLabel } = item;
      return buildLink(
        articleTeaserLabel,
        resolve('pages:detail', { pageSlug: articleTeaserSlug })
      );

    case 'ContentfulSectionRelatedReading':
      const { buttonSlug: relatedReadingSlug, buttonText: relatedReadingLabel } = item;
      return buildLink(
        relatedReadingLabel,
        resolve('pages:detail', { pageSlug: relatedReadingSlug })
      );

    case 'ContentfulSectionOurClients':
      const { ctaButtonSlug: ourClientsSlug, ctaButtonText: ourClientsLabel } = item;
      return buildLink(ourClientsLabel, resolve('pages:detail', { pageSlug: ourClientsSlug }));

    case 'ContentfulPartInfoboxTextElement':
      const {
        link: { slug: infoBoxSlug, title: infoBoxLabel },
      } = item;
      return buildLink(infoBoxLabel, resolve('industry:detail', { industrySlug: infoBoxSlug }));

    case 'ContentfulSectionOpener':
      const { buttonSlug: sectionOpenerSlug, buttonText: sectionOpenerLabel } = item;
      return buildLink(
        sectionOpenerLabel,
        resolve('pages:detail', { pageSlug: sectionOpenerSlug })
      );

    case 'ContentfulOffices':
      const { slug: officeSlug, city } = item;
      return buildLink(city, resolve('offices:detail', { officeSlug }));

    default:
      throw new Error(`Link object contains unknown or invalid entry type "${typename}"`);
  }
};

module.exports.getLink = getLink;
