import React from 'react';

export default (props) => (
  <svg
    version="1.1"
    // id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2184 269"
    xmlSpace="preserve"
    className={props.className ? props.className : null}
  >
    <g>
      <path
        d="M37.6,148.6C37.6,103.5,58.3,84,93,84c32.5,0,50.8,15.7,50.8,60v13.7H74c0.2,22.4,6.5,31.8,27.7,31.8
		c13.7,0,21.2-3.4,32.1-9.6l9.2,19.3c-12.5,9.2-26.3,14.5-46.3,14.5C57.6,213.7,37.6,195.1,37.6,148.6z M74.2,137.3h36.1
		c0-26-3.9-32.8-17.1-32.8C81.2,104.5,75.2,110.8,74.2,137.3z"
      />
      <path
        d="M237.6,198.5c-8.4,9.9-18.1,15.2-32.5,15.2c-27,0-44.3-16.6-44.3-64.3c0-49.2,19.5-65.3,46-65.3
		c13.5,0,21,6,28,11.8V56.6l-14.5-3.1V34.1h48.4v156.6l14.5,2.4v17.4h-42.4L237.6,198.5z M234.7,183.1v-66.3
		c-5.1-3.9-8.7-7.2-17.4-7.2c-15.2,0-20.2,10.1-20.2,40.5c0,29.4,4.8,39.8,18.1,39.8C223.1,189.8,230.1,186.7,234.7,183.1z"
      />
      <path
        d="M298.6,148.6c0-45.1,20.7-64.6,55.4-64.6c32.5,0,50.8,15.7,50.8,60v13.7H335c0.2,22.4,6.5,31.8,27.7,31.8
		c13.7,0,21.2-3.4,32.1-9.6l9.2,19.3c-12.5,9.2-26.3,14.5-46.3,14.5C318.6,213.7,298.6,195.1,298.6,148.6z M335.2,137.3h36.1
		c0-26-3.9-32.8-17.1-32.8C342.2,104.5,336.2,110.8,335.2,137.3z"
      />
      <path
        d="M504.4,128.9c0-12.3-3.4-17.1-12.8-17.1c-7.2,0-14.9,3.1-21.2,7v72.1l13.7,2.4v17.4h-62.2v-17.4l14.5-2.4V111
		l-14.5-3.4V87.2h43.6l3.4,13.7C481.2,90.1,492.6,84,508.2,84c19.8,0,30.4,11.8,30.4,33.5v73.3l14.2,2.4v17.4h-48.4V128.9z"
      />
      <path
        d="M569.9,205.2v-32.1h22.9l4.1,18.1c4.3,1.4,7.5,2.4,14.2,2.4c13.5,0,19.3-4.8,19.3-14
		c0-10.1-6.5-14.2-23.9-18.3c-23.1-6-36.9-15.4-36.9-39.5c0-22.4,16.4-37.8,47.7-37.8c17.1,0,29.2,2.7,38.8,7.5v31.6h-21.9
		l-4.1-17.1c-3.9-1.4-7.7-1.9-13.3-1.9c-11.1,0-17.6,4.1-17.6,12.3c0,8.4,5.3,12.3,23.9,16.9c24.6,6.5,38.3,15.7,38.3,41.9
		c0,25.5-20,38.6-49.4,38.6C595,213.7,580.3,210.5,569.9,205.2z"
      />
      <path
        d="M724.1,101.1c6.7-7.5,18.8-17.1,34.5-17.1c23.1,0,42.4,14.2,42.4,62.4c0,51.8-18.8,66.3-46.8,66.3
		c-12,0-21.9-3.9-28.9-10.4v35.9l14.5,2.4v16.4h-62.9v-16.4l14.5-2.4V111l-14.5-3.4V87.2h43.6L724.1,101.1z M764.4,148.4
		c0-29.6-4.1-38.6-18.6-38.6c-8.2,0-15.2,2.9-20.5,6.7v67c4.3,3.4,11.6,7,19.3,7C760.3,190.5,764.4,178.5,764.4,148.4z"
      />
      <path
        d="M882,193.2v17.4h-62.9v-17.4l14.5-2.4V111l-14.5-3.4V87.2h48.4v103.6L882,193.2z M829.2,54.6
		c0-11.1,8.9-20,20-20c11.3,0,20,8.9,20,19.8s-8.7,19.8-19.8,19.8C838.1,74.1,829.2,65.2,829.2,54.6z"
      />
      <path
        d="M896.7,148.6c0-45.1,20.7-64.6,55.4-64.6c32.5,0,50.8,15.7,50.8,60v13.7h-69.9c0.2,22.4,6.5,31.8,27.7,31.8
		c13.7,0,21.2-3.4,32.1-9.6l9.2,19.3c-12.5,9.2-26.3,14.5-46.3,14.5C916.7,213.7,896.7,195.1,896.7,148.6z M933.3,137.3h36.1
		c0-26-3.9-32.8-17.1-32.8C940.3,104.5,934.3,110.8,933.3,137.3z"
      />
      <path
        d="M1067.5,190.8l12.8,2.4v17.4h-61v-17.4l14.2-2.4v-134l-14.2-3.4V34.1h48.2V190.8z M1101,108.8l-12-1.7v-20
		h51.8v20.5l-14.7,1.9l-26.5,29.6l33.5,51.6l12.5,2.2v17.6h-38.1l-39.5-63.1L1101,108.8z"
      />
      <path
        d="M1157.2,148.6c0-45.1,20.7-64.6,55.4-64.6c32.5,0,50.8,15.7,50.8,60v13.7h-69.9c0.2,22.4,6.5,31.8,27.7,31.8
		c13.7,0,21.2-3.4,32.1-9.6l9.2,19.3c-12.5,9.2-26.3,14.5-46.3,14.5C1177.2,213.7,1157.2,195.1,1157.2,148.6z M1193.8,137.3h36.1
		c0-26-3.9-32.8-17.1-32.8C1200.8,104.5,1194.8,110.8,1193.8,137.3z"
      />
      <path
        d="M1348,193.2v17.4h-67.5v-17.4l14.5-2.4V111l-14.5-3.4V87.2h42.4l4.3,20c8.4-15.2,20-22.7,35.7-22.7
		c1.7,0,4.1,0.2,5.3,0.7l-4.3,35.2c-3.4-1.7-6.7-2.7-11.8-2.7c-9.2,0-16.1,2.4-23.1,6.7v65.8L1348,193.2z"
      />
      <path
        d="M1494.3,101.4c12.3-11.1,22.9-17.4,37.8-17.4c19.3,0,29.9,11.8,29.9,33.5v74.2l14.5,2.4v16.4H1528v-81.2
		c0-12.3-3.1-17.4-12.5-17.4c-7,0-13.3,2.4-19.5,6.5v72.3l13.5,2.4v17.4h-47.7v-81.2c0-12.3-2.9-17.4-12.5-17.4
		c-6.5,0-13,2.4-19.3,6.5v72.3l13.5,2.4v17.4h-61.9v-17.4l14.5-2.4V111l-14.5-3.4V87.2h43.6l3.4,13.7c12.3-10.6,22.4-16.9,37.6-16.9
		C1480.1,84,1490.2,90.1,1494.3,101.4z"
      />
      <path
        d="M1705.4,208.1c-4.6,2.7-12.5,5.5-21.9,5.5c-11.6,0-20-3.1-23.4-13.3c-7.5,7.2-14.9,13.3-31.3,13.3
		c-21.7,0-37.4-11.1-37.4-37.6c0-27.7,17.1-39.3,58.6-39.3h7.2v-6c0-13.3-3.1-19.5-17.8-19.5c-12.8,0-23.6,3.4-35.2,8.9l-9.6-21.7
		c14-9.2,31.1-14.5,51.8-14.5c29.4,0,44.8,10.6,44.8,40.5v57.1c0,8.2,1.9,9.6,12.8,9.6L1705.4,208.1z M1657.2,184.8v-29.9h-7.7
		c-16.9,0-24.1,3.9-24.1,19.3c0,12,4.6,16.9,14.5,16.9C1648,191,1653.1,188.4,1657.2,184.8z"
      />
      <path
        d="M1803.9,128.9c0-12.3-3.4-17.1-12.8-17.1c-7.2,0-14.9,3.1-21.2,7v72.1l13.7,2.4v17.4h-62.2v-17.4l14.5-2.4V111
		l-14.5-3.4V87.2h43.6l3.4,13.7c12.3-10.8,23.6-16.9,39.3-16.9c19.8,0,30.4,11.8,30.4,33.5v73.3l14.2,2.4v17.4h-48.4V128.9z"
      />
      <path
        d="M1950.9,128.9c0-12.3-3.4-17.1-12.8-17.1c-7.2,0-14.9,3.1-21.2,7v72.1l13.7,2.4v17.4h-62.2v-17.4l14.5-2.4V111
		l-14.5-3.4V87.2h43.6l3.4,13.7c12.3-10.8,23.6-16.9,39.3-16.9c19.8,0,30.4,11.8,30.4,33.5v73.3l14.2,2.4v17.4h-48.4V128.9z"
      />
      <path d="M2007.1,223.6h137.4v24.6h-137.4V223.6z" />
    </g>
  </svg>
);
