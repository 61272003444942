import React from 'react';

import BlockNewsletterSignup from '../blocks/block-newsletter-signup';
import { usePrivacyBanner } from '../hooks/usePrivacy';
import { useRegion } from '../hooks/useRegion';
import { resolve } from '../urls';

import EspiLogo from './espi-logo';
import Image from './image';
import Link from './link';
import SocialLinks from './social-links';

const Footer = (props) => {
  const {
    image,
    siteLinks,
    officeLinks,
    copyrightNotice,
    signupForm,
    facebookUrl,
    twitterUrl,
    instagramUrl,
    linkedinUrl,
    devBlogUrl,
  } = props;
  const { setShouldShowPrivacyBanner } = usePrivacyBanner();
  const { whatRegion } = useRegion();

  return (
    <footer className="footer">
      <div className="footer__main-image-wrapper">
        {image && <Image className="footer__main-image" image={image} alt={image.title} />}
      </div>

      <div className="footer__column-wrapper">
        <div className="footer__left-column">
          <div className="footer__left-column-content-wrap">
            <div className="footer__top-content">
              <Link to={resolve('index', { locale: whatRegion })} aria-label="Edenspiekermann">
                <EspiLogo className="footer__espi-logo-wrap" />
              </Link>

              <div className="footer__site-links-wrap">
                {siteLinks.map((link, i) => (
                  <Link className="footer__link" to={link.url} key={i}>
                    {link.title}
                  </Link>
                ))}
                <button
                  onClick={() => setShouldShowPrivacyBanner(true)}
                  className="footer__link"
                  type="button"
                >
                  Change cookie settings
                </button>
              </div>
            </div>

            <div className="footer__bottom-content">
              <div className="footer__socials-wrap">
                <SocialLinks
                  facebookUrl={facebookUrl || null}
                  instagramUrl={instagramUrl || null}
                  linkedinUrl={linkedinUrl || null}
                  twitterUrl={twitterUrl || null}
                  devBlogUrl={devBlogUrl || null}
                />
              </div>
              <div className="footer__offices-wrap">
                {officeLinks.map((office, i) => (
                  <Link
                    className="footer__link"
                    to={resolve('offices:detail', { officeSlug: office.slug })}
                    key={i}
                  >
                    {office.city}
                  </Link>
                ))}
              </div>

              <div className="footer__meta-wrap">
                {copyrightNotice && <div className="meta-wrap__copyright">{copyrightNotice}</div>}
              </div>
            </div>
          </div>
        </div>

        <div className="footer__right-column">
          <div className="footer__newsletter-wrap">
            {signupForm && <BlockNewsletterSignup {...signupForm} />}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
