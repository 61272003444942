import { canUseDOM } from 'exenv';
import { useEffect } from 'react';

export const usePolyfill = (testFn, loadFn, callback = () => undefined) => {
  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    if (testFn()) {
      callback();
      return;
    }

    loadFn().then(() => callback());
  }, [testFn, loadFn, callback]);
};
