import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useRegion } from '../hooks/useRegion';
import { getLink, resolve } from '../urls';

import EspiLogo from './espi-logo';
import Image from './image';
import Link from './link';
import SocialLinks from './social-links';

const Routes = ({ className, routes }) => {
  const { whatRegion } = useRegion();

  return (
    <ul className={className}>
      {routes.map((route, i) => (
        <li key={i}>
          <Link
            link={getLink(route, whatRegion)}
            className="navbar__link"
            activeClassName="navbar__link--active"
            partiallyActive
          />
        </li>
      ))}
    </ul>
  );
};

const Navbar = ({
  navbarIsInverted,
  routes,
  secundaryRoutes,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
  twitterUrl,
  devBlogUrl,
  image,
}) => {
  const { whatRegion } = useRegion();

  const [menuDropdownIsOpen, setMenuDropdownIsOpen] = useState(false);

  const toggleMenuDropdown = () => {
    setMenuDropdownIsOpen(!menuDropdownIsOpen);
  };

  useEffect(() => {
    const root = document.getElementsByTagName('html')[0];
    const { body } = document;
    if (menuDropdownIsOpen) {
      root.classList.add('menu-open');
      body.classList.add('menu-open');
    } else {
      root.classList.remove('menu-open');
      body.classList.remove('menu-open');
    }
  }, [menuDropdownIsOpen]);

  const navStyles = classNames({
    'navbar': true,
    'navbar--inverted': navbarIsInverted,
    'navbar--menu-open': menuDropdownIsOpen,
  });

  return (
    <nav className={navStyles}>
      <div className="navbar__flex">
        <Link
          to={resolve('index', { locale: whatRegion })}
          className="navbar__logo-wrap"
          aria-label="Edenspiekermann"
        >
          <EspiLogo />
        </Link>

        <button className="navbar__toggle" onClick={toggleMenuDropdown} aria-label="Open Menu">
          <span />
          <span />
          <span />
        </button>

        <div className="navbar__menu-wrapper" aria-expanded={menuDropdownIsOpen}>
          <div className="navbar__menu-list">
            <Routes
              className="menu-list__primary"
              routes={routes}
              toggleMenuDropdown={toggleMenuDropdown}
            />
            <Routes
              className="menu-list__secundary"
              routes={secundaryRoutes}
              toggleMenuDropdown={toggleMenuDropdown}
            />
            <SocialLinks
              facebookUrl={facebookUrl || null}
              instagramUrl={instagramUrl || null}
              linkedinUrl={linkedinUrl || null}
              twitterUrl={twitterUrl || null}
              devBlogUrl={devBlogUrl || null}
            />
          </div>
          <Image
            className="navbar__background-image"
            image={image}
            alt=""
            aria-hidden="true"
            role="presentation"
          />
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  navbarIsInverted: PropTypes.bool,
  routes: PropTypes.array,
  title: PropTypes.string,
  image: PropTypes.object,
};

export default Navbar;
