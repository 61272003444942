import classNames from 'classnames';
import React from 'react';

import EdnaInputCheckbox from './edna-input-checkbox';
import Markdown from './markdown';

const NewsletterForm = (props) => {
  const {
    formActionUrl,
    emailFieldName,
    honeypotFieldName,
    fnameFieldName,
    lnameFieldName,
    companyFieldName,
    theme,
    button_text,
    invertedButton,
    privacyNotice,
    consentCheckBoxes,
    // Below are react-mailchimp-subscribe props
    status,
    message,
    onSubmitted,
    onValidated,
  } = props;

  // Form Refs
  const firstNameRef = React.createRef();
  const lastNameRef = React.createRef();
  const companyRef = React.createRef();
  const emailRef = React.createRef();

  const formClasses = classNames({
    'newsletter-form': true,
    [`newsletter-form--over-${theme}`]: !!theme,
  });

  const formButtonClasses = classNames({
    'newsletter-form__button': true,
    'button': true,
    'button--inverted': !!invertedButton, // check truthiness of invertedButton prop
    // 'button--alternative': !invertedButton
  });

  // Todo - add validation, and dynamic onSubmitted fields
  const submit = (e) => {
    // e.preventDefault();
    // onSubmitted({
    //   [emailFieldName]: emailRef.current.value
    // });
  };

  // emailRef &&
  // emailRef.value.indexOf('@') > -1 &&

  return (
    <form action={formActionUrl} className={formClasses} method="post">
      <div className="newsletter-form__inputs">
        {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
        {status === 'error' && (
          <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <div style={{ color: 'green' }} dangerouslySetInnerHTML={{ __html: message }} />
        )}

        {fnameFieldName && (
          <div className="newsletter-form__input-row">
            <div className="edna-input-text">
              <input
                className="edna-input-text__input"
                name={fnameFieldName}
                ref={firstNameRef}
                placeholder="Enter your first name"
                type="text"
                id="newsletter-form-fname"
                required
              />
              <label className="edna-input-text__label" htmlFor="newsletter-form-fname">
                First name
              </label>
            </div>
          </div>
        )}

        {lnameFieldName && (
          <div className="newsletter-form__input-row">
            <div className="edna-input-text">
              <input
                className="edna-input-text__input"
                name={lnameFieldName}
                ref={lastNameRef}
                placeholder="Enter your last name"
                type="text"
                id="newsletter-form-lname"
                required
              />
              <label className="edna-input-text__label" htmlFor="newsletter-form-lname">
                Last name
              </label>
            </div>
          </div>
        )}

        {companyFieldName && (
          <div className="newsletter-form__input-row">
            <div className="edna-input-text">
              <input
                className="edna-input-text__input"
                name={companyFieldName}
                ref={companyRef}
                placeholder="Enter your company’s name"
                type="text"
                id="newsletter-form-company"
                required
              />
              <label className="edna-input-text__label" htmlFor="newsletter-form-company">
                Company name
              </label>
            </div>
          </div>
        )}

        {emailFieldName && (
          <div className="newsletter-form__input-row">
            <div className="edna-input-text">
              <input
                className="edna-input-text__input"
                name={emailFieldName}
                ref={emailRef}
                placeholder="Enter your email address"
                type="email"
                id="newsletter-form-email"
                required
              />
              <label className="edna-input-text__label" htmlFor="newsletter-form-email">
                Email Address
              </label>
            </div>
          </div>
        )}

        {honeypotFieldName && (
          <div style={{ position: 'absolute', left: '-5000px' }}>
            <input name={honeypotFieldName} tabIndex="-1" type="email" aria-hidden />
          </div>
        )}
      </div>

      {consentCheckBoxes && (
        <div className="newsletter-form__consents">
          {consentCheckBoxes.map((checkbox, i) => (
            <div className="newsletter-form__input-row" key={i}>
              <EdnaInputCheckbox {...checkbox}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: checkbox.label.childMarkdownRemark.html,
                  }}
                />
              </EdnaInputCheckbox>
            </div>
          ))}
        </div>
      )}
      {button_text && (
        <div className="newsletter-form__submit">
          <button
            className={formButtonClasses}
            id="mc-embedded-subscribe"
            type="submit"
            onClick={submit}
            aria-label={button_text}
          >
            {button_text}
          </button>
        </div>
      )}

      {privacyNotice && (
        <Markdown
          className="newsletter-form__privacy-notice"
          markdown={privacyNotice.childMarkdownRemark.html}
        />
      )}
    </form>
  );
};

export default NewsletterForm;
