import classNames from 'classnames';
import React from 'react';

const Markdown = (props) => {
  const { markdown, className, isChapter, itemProp, children } = props;

  let markdownClasses;
  if (className) {
    markdownClasses = classNames({
      [className]: true,
    });
  } else {
    markdownClasses = classNames({
      'copy': true,
      'copy--chapter': isChapter,
    });
  }

  const parsed = <div dangerouslySetInnerHTML={{ __html: markdown }} />;

  return (
    <div itemProp={itemProp} className={markdownClasses}>
      {children ? children(parsed) : parsed}
    </div>
  );
};

export default Markdown;
