import classNames from 'classnames';
import React from 'react';

const SectionOpener = (props) => {
  const openerClasses = classNames({
    'section-opener': true,
    [`section-opener--${props.openerType}`]:
      typeof props.openerType !== 'undefined' && props.openerType !== null,
  });
  return <div className={`${openerClasses} ${props.className ?? ''}`}>{props.children}</div>;
};

export default SectionOpener;
